import { json, LinksFunction, LoaderFunction } from '@remix-run/node';
import { Link, Outlet } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '@assets/css/client-common.css?url';
import down_01 from '@assets/images/down_01.png';
import footer_logo from '@assets/images/footer_logo.png';
import Group_62 from '@assets/images/Group_62.png';
import link_icon_01 from '@assets/images/link_icon_01.png';
import logo_up from '@assets/images/logo_up.png';
import navi_close from '@assets/images/navi_close.png';
import navi_icon from '@assets/images/navi_icon.png';
import sns_blog from '@assets/images/sns_blog.png';
import sns_blog_w from '@assets/images/sns_blog_w.png';
import sns_facebook from '@assets/images/sns_facebook.png';
import sns_facebook_w from '@assets/images/sns_facebook_w.png';
import sns_insta from '@assets/images/sns_insta.png';
import sns_insta_w from '@assets/images/sns_insta_w.png';
import sns_kakao from '@assets/images/sns_kakao.png';
import sns_kakao_w from '@assets/images/sns_kakao_w.png';
import Vector_13 from '@assets/images/Vector_13.png';
import MainLoadingSpinner from '@components/MainLoadingSpinner';
import useHeaderScroll from '@hooks/useHeaderScroll';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export const loader: LoaderFunction = async ({ request }) => {
    return json({});
};

export default function ClientLayout() {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
            <MainLoadingSpinner />
        </>
    );
}

function Header() {
    const headerRef = useRef<HTMLElement | null>(null);
    const navigate = useNavigate();
    const [pcAct, setPcAct] = useState('');
    const [moAct, setMoAct] = useState('');
    const [open, setOpen] = useState('');

    const { scroll } = useHeaderScroll();

    const moIconOpen = () => {
        setOpen('on');
    };

    const moIconClose = () => {
        setOpen('');
    };

    useEffect(() => {
        const header = headerRef.current;

        const handleMouseEnter = () => {
            if (header) {
                header.classList.add('act');
            }
        };

        const handleMouseLeave = () => {
            if (header) {
                header.classList.remove('act');
            }
        };

        if (header) {
            header.addEventListener('mouseenter', handleMouseEnter);
            header.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (header) {
                header.removeEventListener('mouseenter', handleMouseEnter);
                header.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);

    const modalNavigate = (location: string) => {
        navigate(location);
    };

    return (
        <header ref={headerRef} className={`header ${scroll}`}>
            <div className="in">
                <div className="header_con">
                    <Link to="/" className="logo">
                        <img className="com_img switch_1" src={logo_up} alt="" />
                        <img className="com_img switch_2" src={footer_logo} alt="" />
                    </Link>
                    <div className="menu_list">
                        <Link to="/intro" className="menu cate_2">
                            회사소개
                        </Link>
                        <Link to="/brand" className="menu cate_2">
                            브랜드소개
                        </Link>
                        <Link to="/product" className="menu cate_2">
                            제품안내
                        </Link>
                        <Link to="/board" className="menu cate_2">
                            새소식
                        </Link>
                        <Link to="#" className="menu cate_2">
                            쇼핑몰
                        </Link>
                    </div>
                    <div className="header_remote">
                        <div className="header_lang">
                            <Link to="#" className="head_txt cate_3 on">
                                KR
                            </Link>
                            <Link to="#" className="head_txt cate_3">
                                EN
                            </Link>
                        </div>
                        <span className="mo_icon" onClick={moIconOpen}>
                            <span className="mo_line"></span>
                            <span className="mo_line"></span>
                            <span className="mo_line"></span>
                        </span>
                    </div>
                </div>

                <div className={`navi_con ${open}`}>
                    <div className="navi_in">
                        <div>
                            <div className="navi_logo">
                                <img className="com_img" src={logo_up} alt="" />
                            </div>
                            <div className="navi_copy_right_con">
                                <p className="navi_copy_right pc">Copyright © Noolerfood. All right reserved.</p>
                                <p className="navi_copy_right mo">
                                    Copyright ©<br />
                                    Noolerfood. All right reserved.
                                </p>
                            </div>
                            <div className="navi_menu_con">
                                <div className="navi_menu_items">
                                    <Link
                                        to="#"
                                        className="navi_menu_unit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            modalNavigate('/intro');
                                            moIconClose();
                                        }}
                                    >
                                        <span className="navi_menu tit_3">회사소개</span>
                                        <img className="navi_icon" src={navi_icon} alt="" />
                                        <img className="navi_icon mo" src={Vector_13} alt="" />
                                    </Link>
                                    <Link
                                        to="#"
                                        className="navi_menu_unit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            modalNavigate('/brand');
                                            moIconClose();
                                        }}
                                    >
                                        <span className="navi_menu tit_3">브랜드소개</span>
                                        <img className="navi_icon" src={navi_icon} alt="" />
                                        <img className="navi_icon mo" src={Vector_13} alt="" />
                                    </Link>
                                    <Link
                                        to="#"
                                        className="navi_menu_unit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            modalNavigate('/product');
                                            moIconClose();
                                        }}
                                    >
                                        <span className="navi_menu tit_3">제품안내</span>
                                        <img className="navi_icon" src={navi_icon} alt="" />
                                        <img className="navi_icon mo" src={Vector_13} alt="" />
                                    </Link>
                                    <Link
                                        to="#"
                                        className="navi_menu_unit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            modalNavigate('/board');
                                            moIconClose();
                                        }}
                                    >
                                        <span className="navi_menu tit_3">새소식</span>
                                        <img className="navi_icon" src={navi_icon} alt="" />
                                        <img className="navi_icon mo" src={Vector_13} alt="" />
                                    </Link>
                                    <Link
                                        to="#"
                                        className="navi_menu_unit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            moIconClose();
                                        }}
                                    >
                                        <span className="navi_menu tit_3">쇼핑몰</span>
                                        <img className="navi_icon" src={navi_icon} alt="" />
                                        <img className="navi_icon mo" src={Vector_13} alt="" />
                                    </Link>
                                    <Link
                                        to="#"
                                        className="navi_menu_unit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            modalNavigate('/contact');
                                            moIconClose();
                                        }}
                                    >
                                        <span className="navi_menu tit_3">문의하기</span>
                                        <img className="navi_icon" src={navi_icon} alt="" />
                                        <img className="navi_icon mo" src={Vector_13} alt="" />
                                    </Link>
                                </div>
                                <div className="navi_close">
                                    <img className="close_img pc" src={navi_close} alt="" onClick={moIconClose} />
                                    <img className="close_img mo" src={Group_62} alt="" onClick={moIconClose} />
                                </div>
                            </div>
                            <div className="navi_lang">
                                <Link to="#" className="lang on">
                                    KR
                                </Link>
                                <Link to="#" className="lang">
                                    EN
                                </Link>
                            </div>
                        </div>

                        <div className="navi_sns_list">
                            <Link to="#" className="sns_unit">
                                <img className="com_img" src={sns_insta_w} alt="" />
                            </Link>
                            <Link to="#" className="sns_unit">
                                <img className="com_img" src={sns_facebook_w} alt="" />
                            </Link>
                            <Link to="#" className="sns_unit">
                                <img className="com_img" src={sns_kakao_w} alt="" />
                            </Link>
                            <Link to="#" className="sns_unit">
                                <img className="com_img" src={sns_blog_w} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

function Footer() {
    const [dropdown, setDropdown] = useState(false);

    const toggle = () => {
        setDropdown(!dropdown);
    };

    return (
        <footer>
            <div className="f_top">
                <div className="in">
                    <div className="f_top_con">
                        <div className="priv_con">
                            <Link to="#" className="priv_txt">
                                개인정보처리방침
                            </Link>
                            <Link to="#" className="priv_txt">
                                이용약관
                            </Link>
                        </div>

                        <select
                            className="family_select"
                            onChange={(e) => {
                                switch (e.target.value) {
                                    case '1':
                                        window.open('https://okyunsunideagroup.modoo.at/');
                                        break;
                                    default:
                                        break;
                                }
                            }}
                        >
                            <option value="">Family Site</option>
                            <option value="1">(주)옥윤선아이디어그룹</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="f_bottom">
                <div className="in">
                    <div className="f_con">
                        <div className="grp">
                            <div className="f_logo">
                                <img className="com_img" src={footer_logo} alt="" />
                            </div>
                            <div className="f_txt_con">
                                <p className="f_tit_logo">주식회사 옥윤선푸드그룹</p>
                                <div className="f_info">
                                    <div className="unit">
                                        <p className="f_tit">대표자</p>
                                        <p className="f_copy">옥윤선</p>
                                    </div>
                                    <div className="unit">
                                        <p className="f_tit">주소</p>
                                        <p className="f_copy">광주광역시 광산구 풍영정길 297-1, 물결가을 빌딩</p>
                                    </div>
                                    <div className="unit">
                                        <p className="f_tit">E-mail</p>
                                        <p className="f_copy">okyunsungroup@gmail.com</p>
                                    </div>
                                    <div className="unit">
                                        <p className="f_tit">사업자 등록번호</p>
                                        <p className="f_copy">860-86-03266</p>
                                    </div>
                                    <div className="unit">
                                        <p className="f_tit">통신판매업신고번호</p>
                                        <p className="f_copy">제 2024-광주광산-0571호</p>
                                    </div>
                                    <div className="unit">
                                        <p className="f_tit">Tel.</p>
                                        <p className="f_copy">070-4322-0254</p>
                                    </div>
                                    <div className="unit">
                                        <p className="f_tit">고객센터</p>
                                        <p className="f_copy">070-4322-0254</p>
                                    </div>
                                </div>
                            </div>
                            <div className="f_sns_list">
                                <Link className="sns_unit" to="#">
                                    <img className="com_img" src={sns_insta} alt="" />
                                </Link>
                                <Link className="sns_unit" to="#">
                                    <img className="com_img" src={sns_facebook} alt="" />
                                </Link>
                                <Link className="sns_unit" to="#">
                                    <img className="com_img" src={sns_kakao} alt="" />
                                </Link>
                                <Link className="sns_unit" to="#">
                                    <img className="com_img" src={sns_blog} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="copy_right">
                            <p className="copy">Copyright © Noolerfood. All right reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
